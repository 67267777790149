<template>
    <v-app class="pa-0 ma-0">
        <v-main class="pa-0 ma-0">
            <router-view />
        </v-main>

        <!-- Global Snackbar -->
        <v-snackbar
            v-model="snackbar.state.show"
            :color="snackbar.state.color"
            :timeout="snackbar.state.timeout"
            :rounded="snackbar.state.rounded"
            :variant="snackbar.state.variant"
            :location="snackbar.state.location"
            class="text-body-1 mb-4 mr-4"
        >
            {{ snackbar.state.message }}
            
            <template v-slot:actions>
                <v-btn
                    variant="text"
                    icon="mdi-close"
                    @click="snackbar.hideSnackbar"
                />
            </template>
        </v-snackbar>
    </v-app>
</template>

<script setup lang="ts">
import { RouterView } from "vue-router";
import { useSnackbar } from '@/composables/useSnackbar'

const snackbar = useSnackbar()

</script>

<style>
.v-snackbar__wrapper {
  min-width: unset !important;
}
</style>
