interface AnalyticsEvent {
    category: string;
    action: string;
    label?: string;
    value?: number;
}

export function useAnalytics() {
    const trackEvent = ({ category, action, label, value }: AnalyticsEvent) => {
        if (window.gtag) {
            window.gtag('event', action, {
                event_category: category,
                event_label: label,
                value: value
            });
        }
    };

    const trackPageView = (path: string, title: string) => {
        if (window.gtag) {
            window.gtag('config', 'G-QLM1BMZLQT', {
                page_path: path,
                page_title: title
            });
        }
    };

    const trackUserProperties = (properties: Record<string, any>) => {
        if (window.gtag) {
            window.gtag('set', 'user_properties', properties);
        }
    };

    return {
        trackEvent,
        trackPageView,
        trackUserProperties
    };
} 