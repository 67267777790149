export const API_URL = import.meta.env.DEV ? "http://localhost:5000/" : "https://api.gemimakine.com/"

// Auth endpoints
export const AUTH_ENDPOINTS = {
    LOGIN: 'auth/login',
    REGISTER: 'auth/register',
    USER: 'auth/user',
    LOGOUT: 'auth/logout',
    REFRESH: 'auth/refresh',
    CONFIRM: 'auth/confirm',
    RESEND_CONFIRMATION: 'auth/resend-confirmation-link',
    CHECK_SESSION: 'auth/check-session',
    RESET_PASSWORD_REQUEST: 'auth/reset-password-request',
    RESET_PASSWORD: 'auth/reset-password',
    CHANGE_PASSWORD: 'auth/change-password'
} as const

// General endpoints
export const GENERAL_ENDPOINTS = {
    PACKAGES: 'general/packages',
    SITE_STATS: 'general/stats'
} as const

// Stats endpoints
export const STATS_ENDPOINTS = {
    DAILY_STATS: 'stats/daily-stats',
    COURSE_STATS: 'stats/stats-by-course'
} as const

// User endpoints
export const USER_ENDPOINTS = {
    SAVED_QUESTIONS: 'user/saved-questions',
    SAVE_QUESTIONS: 'user/save-questions',
    UNSAVE_QUESTIONS: 'user/unsave-questions',
    TEST_HISTORY: 'user/test-history',
    COURSE_STATS: 'stats/stats-by-course'
} as const

// Test endpoints
export const TEST_ENDPOINTS = {
    CREATE_TEST: 'test/create-test',
    SUBMIT_TEST: 'test/submit-test',
    REPORT_ISSUE: 'test/report-issue'
} as const

// Poll endpoints
export const POLL_ENDPOINTS = {
    GET_POLL: 'poll/get-poll',
    VOTE_POLL: 'poll/vote'
} as const

// Library endpoints
export const LIBRARY_ENDPOINTS = {
    FILES: 'library/files',
    DOWNLOAD: (id: string) => `library/files/${id}/download`
} as const

// Contact endpoints
export const CONTACT_ENDPOINTS = {
    SEND: 'contact'
} as const
