import { createApp, nextTick } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import { router } from '@/app/router';
import vuetify from '@/core/plugins/vuetify';
import '@/app/scss/style.scss';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import Maska from 'maska';
import { authGuard } from './app/router/guards/auth.guard';
import { useErrorLogger } from '@/composables/useErrorLogger';

router.beforeEach(authGuard)

const DEFAULT_TITLE = 'Gemi Makine - Sınavlara & Mesleğe Hazırlık';
router.afterEach((to, from) => {
    nextTick(() => {
        document.title = (to.meta.title as string) || DEFAULT_TITLE;
    });
});

const app = createApp(App);

// Global error handler
app.config.errorHandler = async (error, instance, info) => {
    console.log('Global Error:', error);
    console.log('Instance:', instance);
    console.log('Info:', info);
    const { logError } = useErrorLogger();
    await logError(error, {
        componentInfo: instance?.$options?.name || 'Unknown Component',
        errorInfo: info,
        vueInfo: {
            componentTree: instance?.$parent?.$options?.name,
            props: instance?.$props,
        }
    });
    console.error('Global Error:', error);
};

// Yakalanmayan promise hataları için handler
window.addEventListener('unhandledrejection', async (event) => {
    const { logError } = useErrorLogger();
    await logError(event.reason, {
        type: 'unhandledRejection',
        promise: event.promise
    });
});

app.use(PerfectScrollbar);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(Maska);
app.use(vuetify);
app.use(router)
app.mount('#app')