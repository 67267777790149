export const RoutePaths = {
  ECR: {
    ROOT: '/ecr',
    DASHBOARD: '/ecr/dashboard',
    PROFILE: '/ecr/profile',
    TEST_MAKER: '/ecr/test-maker',
    TEST: '/ecr/test',
    TEST_RESULT: '/ecr/test-result',
    TEST_RESULT_HISTORY: '/ecr/test-result-history',
    SAVED_QUESTIONS: '/ecr/saved-questions',
    PERFORMANCE_ANALYSIS: '/ecr/performance-analysis',
    HELP: '/ecr/help'
  },
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password'
  },
  INDEX: {
    ROOT: '/',
    PACKAGES: '/packages',
    FAQ: '/faq',
    KVKK: '/gizlilik-politikasi',
    MEMBERSHIP_AGREEMENT: '/uyelik-sozlesmesi'
  },
  ERROR: '/:pathMatch(.*)*'
} as const;

export const RouteTitles = {
  APP_NAME: 'Gemi Makine',
  ECR: {
    ROOT: 'ECR',
    DASHBOARD: 'Kullanıcı Paneli',
    PROFILE: 'Profil',
    TEST_MAKER: 'Test Oluştur',
    TEST: 'Test',
    TEST_RESULT: 'Test Sonucu',
    TEST_RESULT_HISTORY: 'Test Sonuç Geçmişi',
    SAVED_QUESTIONS: 'Kaydedilen Sorular',
    PERFORMANCE_ANALYSIS: 'Performans Analizi',
    HELP: 'Yardım',
    LIBRARY: 'Kütüphane'
  },
  AUTH: {
    LOGIN: 'Giriş Yap',
    REGISTER: 'Kayıt Ol',
    FORGOT_PASSWORD: 'Şifremi Unuttum',
    RESET_PASSWORD: 'Şifre Sıfırlama',
    CONFIRM: 'Email Doğrulama'
  },
  INDEX: {
    ROOT: 'Sınavlara & Mesleğe Hazırlık',
    PACKAGES: 'Paketler',
    FAQ: 'Sıkça Sorulan Sorular',
    CONTACT: 'İletişim',
    KVKK: 'GİZLİLİK VE KİŞİSEL VERİLERİ KORUMA POLİTİKASI',
    MEMBERSHIP_AGREEMENT: 'ÜYELİK SÖZLEŞMESİ'
  },
  ERROR: 'Hata'
} as const;


// Title oluşturucu helper fonksiyon
export const createTitle = (title: string) => `${title} | ${RouteTitles.APP_NAME}`;