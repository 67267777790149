// Base API Response Type
export interface ApiResponse<T = any> {
    success: boolean;
    data: T;
    message: string;
    errors?: ApiError[];
    statusCode: number;
}

// API Error Type
export interface ApiError {
    code: string;
    message: string;
    field?: string;
}

// API Error Codes
export enum ApiErrorCode {
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    UNAUTHORIZED = 'UNAUTHORIZED',
    FORBIDDEN = 'FORBIDDEN',
    NOT_FOUND = 'NOT_FOUND',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    BAD_REQUEST = 'BAD_REQUEST'
}

// Custom API Error Class
export class ApiException extends Error {
    constructor(
        public statusCode: number,
        public message: string,
        public errors?: ApiError[]
    ) {
        super(message);
        this.name = 'ApiException';
    }
}

// API Response Helper Functions
export const createSuccessResponse = <T>(data: T, message = 'Success'): ApiResponse<T> => ({
    success: true,
    data,
    message,
    statusCode: 200
});

export const createErrorResponse = (
    message: string,
    statusCode: number = 500,
    errors?: ApiError[]
): ApiResponse => ({
    success: false,
    data: null,
    message,
    errors,
    statusCode
}); 