import { createRouter, createWebHistory } from 'vue-router';
import type { AppRouteType } from './types';
import { RoutePaths, RouteTitles, createTitle } from './router.constants';
import IndexRoutes from './index.routes';
import ECRRoutes from './ecr.routes';
import AuthRoutes from './auth.routes';
import { useAnalytics } from '@/composables/useAnalytics';

const routes: AppRouteType[] = [
    {
        path: RoutePaths.ERROR,
        component: () => import('@/views/other-pages/Error.vue'),
        meta: {
            title: createTitle(RouteTitles.ERROR)
        }
    },
    IndexRoutes,
    AuthRoutes,
    ECRRoutes
];

export const router = createRouter({
    history: createWebHistory('/'),
    routes
});

const { trackPageView } = useAnalytics();

router.afterEach((to) => {
    // Sayfa görüntülemeyi izle
    trackPageView(to.path, to.meta.title as string);
});


export default router;
