import type { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { useAuthStore } from '@/stores/auth.store';
import { router } from '@/app/router'
import { AUTH_ENDPOINTS } from '@/core/constants/api.constants';

// Custom request config interface'i oluştur
interface CustomInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
    _retry?: boolean;
}

interface ApiResponse<T = any> {
    success: boolean;
    message: string;
    statusCode: number;
    data?: T;
    errors?: Record<string, string[]>;
}

export class ApiException extends Error {
    constructor(
        public statusCode: number,
        public message: string,
        public errors?: Record<string, string[]>
    ) {
        super(message);
        this.name = 'ApiException';
    }
}

const createAxiosInstance = (isPrivate = false): AxiosInstance => {
    const instance = axios.create({
        baseURL: import.meta.env.VITE_API_URL,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    });

    // Response Interceptor
    instance.interceptors.response.use(
        (response: AxiosResponse) => {
            const apiResponse = response.data as ApiResponse<any>;

            if (!apiResponse.success) {
                throw new ApiException(
                    apiResponse.statusCode,
                    apiResponse.message,
                    apiResponse.errors
                );
            }

            return response;
        },
        async (error: AxiosError<ApiResponse>) => {
            const originalConfig = error.config as CustomInternalAxiosRequestConfig;

            // Logout endpoint'ine yapılan isteklerde hata kontrolü yapma
            if (originalConfig?.url?.includes(AUTH_ENDPOINTS.LOGOUT)) {
                throw error;
            }

            // Refresh endpoint'ine yapılan isteklerde de hata kontrolü yapma
            if (originalConfig?.url?.includes(AUTH_ENDPOINTS.REFRESH)) {
                throw error;
            }

            // Eğer istek zaten retry edilmişse, tekrar deneme
            if (originalConfig?._retry) {
                throw error;
            }

            if (error.response?.status === 401) {
                if (originalConfig) {
                    originalConfig._retry = true;
                }
                
                const authStore = useAuthStore();
                
                try {
                    await authStore.checkSession();
                    return instance(originalConfig);
                } catch (refreshError) {
                    try {
                        await authStore.logout();
                    } catch (logoutError) {
                        console.error('Logout hatası:', logoutError);
                    }

                    router.push({
                        path: '/login',
                        query: { redirect: window.location.pathname }
                    });
                    
                    throw error;
                }
            }

            // Diğer hataları handle et
            if (error.response?.data) {
                const apiResponse = error.response.data;
                throw new ApiException(
                    apiResponse.statusCode,
                    apiResponse.message,
                    apiResponse.errors
                );
            }
            throw new ApiException(
                500,
                'Sunucuya bağlanırken bir hata oluştu',
                { general: ['Sunucuya bağlanırken bir hata oluştu'] }
            );
        }
    );

    return instance;
};

// Public API instance
export const useApi = () => createAxiosInstance(false);

// Private API instance (requires auth)
export const useApiPrivate = () => createAxiosInstance(true);