import { useAuthStore } from '@/stores/auth.store';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

export const authGuard = async (
    to: RouteLocationNormalized, 
    from: RouteLocationNormalized, 
    next: NavigationGuardNext
) => {
    const authStore = useAuthStore();
    
    try {
        const isAuthenticated = await authStore.checkSession();

        if (to.meta.requiresAuth && !isAuthenticated) {
            return next({
                name: 'Login',
                query: { redirect: to.fullPath }
            });
        }

        if (to.meta.requiresGuest && isAuthenticated) {
            return next({ name: 'Dashboard' });
        }

        return next();
    } catch (error) {
        if (to.meta.requiresAuth) {
            return next({
                name: 'Login',
                query: { redirect: to.fullPath }
            });
        }
        return next();
    }
}; 