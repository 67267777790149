import { defineStore } from 'pinia';
import type { Question } from '@/core/interfaces/question';
import type { TestResult } from '@/core/interfaces/test_result';
import { UserService } from '@/services/user.service';
import { ref } from 'vue';
import { ApiException } from "@/core/types/api.types"

export const useUserStore = defineStore('user', () => {
    // state
    const saved_questions = ref<Question[]>([])
    const test_results = ref<TestResult[]>([])
    const lastLoginDate = ref<Date | null>(null)
    const profileUpdateDate = ref<Date | null>(null)
    const lastTestResultsFetch = ref<Date | null>(null) // Son çekme zamanı

    // Sonuçların yeniden çekilmesi gerekip gerekmediğini kontrol et
    const shouldRefetchResults = (): boolean => {
        if (!lastTestResultsFetch.value) return true;
        
        const oneHour = 60 * 60 * 1000; // 1 saat (milisaniye cinsinden)
        const timeSinceLastFetch = Date.now() - lastTestResultsFetch.value.getTime();
        
        return timeSinceLastFetch > oneHour;
    }

    // Test sonuçlarını getir
    const getTestResults = async (forceRefetch: boolean = false) => {
        try {
            // Eğer zorunlu yenileme istenmemişse ve 1 saat dolmamışsa mevcut sonuçları döndür
            if (!forceRefetch && !shouldRefetchResults() && test_results.value.length > 0) {
                return test_results.value;
            }

            const response = await UserService.getTestHistory(
                test_results.value.length > 0 ? test_results.value[0].completion_date.toString() : undefined
            );

            if (response.data?.test_history && response.data.test_history.length > 0) {
                test_results.value = [...response.data.test_history, ...test_results.value];
                test_results.value.sort((a:TestResult, b:TestResult) => 
                    new Date(b.completion_date!).getTime() - new Date(a.completion_date!).getTime()
                );
            }
            
            // Son çekme zamanını güncelle
            lastTestResultsFetch.value = new Date();
            
            return test_results.value;
        } catch (error) {
            if (error instanceof Error) {
                throw error;
            }
            throw new Error('Failed to fetch test results');
        }
    }

    // Yeni test sonucu eklendiğinde çağrılacak
    const refreshTestResults = () => {
        lastTestResultsFetch.value = null; // Son çekme zamanını sıfırla
        return getTestResults(true); // Zorunlu yenileme ile sonuçları getir
    }

    // Store'u sıfırlamak için $reset metodu
    const $reset = () => {
        saved_questions.value = []
        test_results.value = []
        lastLoginDate.value = null
        profileUpdateDate.value = null
        lastTestResultsFetch.value = null
        
        localStorage.removeItem('user')
    }

    // actions kısmına eklenecek
    const getSavedQuestions = async (): Promise<Question[]> => {
        try {
            const response = await UserService.getSavedQuestions();
            saved_questions.value = response.data.questions;
            return saved_questions.value;
        } catch (error) {
            if (error instanceof Error) {
                throw error;
            }
            throw new Error('Failed to fetch saved questions');
        }
    }

    const unsaveQuestions = async (payload: { question_ids: string[] }) => {
        try {
            return await UserService.unsaveQuestions(payload);
        } catch (error) {
            if (error instanceof Error) {
                throw error;
            }
            throw new Error('Failed to unsave questions');
        }
    }

    return {
        // state
        saved_questions,
        test_results,
        lastLoginDate,
        profileUpdateDate,
        lastTestResultsFetch,
        // actions
        getSavedQuestions,
        unsaveQuestions,
        getTestResults,
        refreshTestResults,
        $reset
    }
}, {
    persist: {
        key: 'user',
        storage: localStorage,
        paths: ['saved_questions', 'test_results', 'lastLoginDate', 'profileUpdateDate', 'lastTestResultsFetch'],
        serializer: {
            serialize: (state) => {
                const serializedState = { ...state }
                // Tarihleri ISO string'e çevir
                if (serializedState.lastLoginDate) {
                    serializedState.lastLoginDate = serializedState.lastLoginDate.toISOString()
                }
                if (serializedState.profileUpdateDate) {
                    serializedState.profileUpdateDate = serializedState.profileUpdateDate.toISOString()
                }
                if (serializedState.lastTestResultsFetch) {
                    serializedState.lastTestResultsFetch = serializedState.lastTestResultsFetch.toISOString()
                }
                return JSON.stringify(serializedState)
            },
            deserialize: (state) => {
                const deserializedState = JSON.parse(state)
                // ISO string'leri Date objesine çevir
                if (deserializedState.lastLoginDate) {
                    deserializedState.lastLoginDate = new Date(deserializedState.lastLoginDate)
                }
                if (deserializedState.profileUpdateDate) {
                    deserializedState.profileUpdateDate = new Date(deserializedState.profileUpdateDate)
                }
                if (deserializedState.lastTestResultsFetch) {
                    deserializedState.lastTestResultsFetch = new Date(deserializedState.lastTestResultsFetch)
                }
                return deserializedState
            }
        }
    }
})