import { reactive } from 'vue'

// Anchor tipini Vuetify'dan import ediyoruz
type Anchor =
  | 'top'
  | 'bottom'
  | 'start'
  | 'end'
  | 'center'
  | 'center center'
  | `${('top' | 'bottom')} ${('start' | 'end' | 'center')}`
  | `${'start' | 'end'} ${'top' | 'bottom' | 'center'}`

interface SnackbarState {
  show: boolean
  message: string
  color: string
  timeout: number
  rounded: string | number | boolean
  variant: "flat" | "text" | "elevated" | "tonal" | "outlined" | "plain"
  location: Anchor
}

interface SnackbarOptions {
  color?: string
  timeout?: number
  rounded?: string | number | boolean
  variant?: "flat" | "text" | "elevated" | "tonal" | "outlined" | "plain"
  location?: Anchor
}

// Varsayılan ayarlar
const defaultOptions: SnackbarOptions = {
  color: 'success',
  timeout: 3000,
  rounded: "sm",
  // variant: 'tonal',
  location: "bottom end" as Anchor
}

// Singleton state
const state = reactive<SnackbarState>({
  show: false,
  message: '',
  color: defaultOptions.color!,
  timeout: defaultOptions.timeout!,
  rounded: defaultOptions.rounded!,
  variant: defaultOptions.variant!,
  location: defaultOptions.location!
})

// Singleton instance
let instance: ReturnType<typeof createSnackbar> | null = null

function createSnackbar() {
  const showMessage = (text: string, options?: SnackbarOptions) => {
    state.message = text
    state.color = options?.color || defaultOptions.color!
    state.timeout = options?.timeout || defaultOptions.timeout!
    state.rounded = options?.rounded || defaultOptions.rounded!
    state.variant = options?.variant || defaultOptions.variant!
    state.location = options?.location || defaultOptions.location!
    state.show = true
  }

  const showSuccess = (text: string, options?: SnackbarOptions) => {
    showMessage(text, { ...options, color: 'success' })
  }

  const showError = (text: string, options?: SnackbarOptions) => {
    showMessage(text, { ...options, color: 'error' })
  }

  const showWarning = (text: string, options?: SnackbarOptions) => {
    showMessage(text, { ...options, color: 'warning' })
  }

  const showInfo = (text: string, options?: SnackbarOptions) => {
    showMessage(text, { ...options, color: 'info' })
  }

  const hideSnackbar = () => {
    state.show = false
  }

  return {
    state,
    showMessage,
    showSuccess,
    showError,
    showWarning,
    showInfo,
    hideSnackbar
  }
}

// Singleton getter
export function useSnackbar() {
  if (!instance) {
    instance = createSnackbar()
  }
  return instance
} 