import type { AppRouteType } from './types';
import { RoutePaths, RouteTitles, createTitle } from './router.constants';

const AuthRoutes: AppRouteType = {
    path: RoutePaths.INDEX.ROOT,
    meta: {
        requiresAuth: false,
        title: createTitle(RouteTitles.AUTH.LOGIN)
    },
    redirect: RoutePaths.INDEX.ROOT,
    component: () => import('@/layouts/index-layout/IndexLayout.vue'),
    children: [
        {
            name: 'Login',
            path: 'login',
            component: () => import('@/views/auth/Login.vue'),
            meta: {
                requiresGuest: true,
                doesNotAllowAuth: true,
                title: createTitle(RouteTitles.AUTH.LOGIN),
                description: 'Hesabına giriş yap',
                keywords: 'gemimakine, hesap, giriş yap'
            }
        },
        {
            name: 'Register',
            path: 'register',
            component: () => import('@/views/auth/Register.vue'),
            meta: {
                requiresGuest: true,
                doesNotAllowAuth: true,
                title: createTitle(RouteTitles.AUTH.REGISTER),
                description: 'Hesap oluştur',
                keywords: 'gemimakine, hesap, oluştur, kayıt ol'
            }
        },
        {
            name: 'Confirm',
            path: 'confirm',
            component: () => import('@/views/auth/Confirm.vue'),
            meta: {
                requiresGuest: true,
                doesNotAllowAuth: true,
                title: createTitle(RouteTitles.AUTH.CONFIRM),
                description: 'Hesabınızı doğrula',
                keywords: 'gemimakine, hesap, doğrula, onayla'
            }
        },
        {
            name: 'ForgotPassword',
            path: 'forgot-password',
            component: () => import('@/views/auth/ForgotPassword.vue'),
            meta: {
                requiresGuest: true,
                doesNotAllowAuth: true,
                title: createTitle(RouteTitles.AUTH.FORGOT_PASSWORD),
                description: 'Şifreni sıfırla',
                keywords: 'gemimakine, hesap, şifre, sıfırla'
            }
        },
        {
            name: 'ResetPassword',
            path: 'reset-password',
            component: () => import('@/views/auth/ResetPassword.vue'),
            meta: {
                requiresGuest: true,
                doesNotAllowAuth: true,
                title: createTitle(RouteTitles.AUTH.RESET_PASSWORD),
                description: 'Şifreni sıfırla',
                keywords: 'gemimakine, hesap, şifre, sıfırla'
            }
        }
    ]
};

export default AuthRoutes;
