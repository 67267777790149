import type { AppRouteType } from './types';
import { RoutePaths, RouteTitles, createTitle } from './router.constants';

const IndexRoutes: AppRouteType = {
    path: RoutePaths.INDEX.ROOT,
    meta: {
        requiresAuth: false,
        title: createTitle(RouteTitles.INDEX.ROOT)
    },
    redirect: RoutePaths.INDEX.ROOT,
    component: () => import('@/layouts/index-layout/IndexLayout.vue'),
    children: [
        {
            name: 'Index',
            path: '/',
            component: () => import('@/views/index/Index.vue'),
            meta: {
                title: createTitle(RouteTitles.INDEX.ROOT)
            }
        },
        {
            name: 'Packages',
            path: 'packages',
            component: () => import('@/views/index/Packages.vue'),
            meta: {
                title: createTitle(RouteTitles.INDEX.PACKAGES)
            }
        },
        {
            name: 'FAQ',
            path: 'faq',
            component: () => import('@/views/index/FAQ.vue'),
            meta: {
                title: createTitle(RouteTitles.INDEX.FAQ)
            }
        },
        {
            name: 'İletişim',
            path: 'contact',
            component: () => import('@/views/Contact.vue'),
            meta: {
                title: createTitle(RouteTitles.INDEX.CONTACT)
            }
        },
        {
            name: 'KVKK',
            path: 'gizlilik-politikasi',
            component: () => import('@/views/index/KVKK.vue'),
            meta: {
                title: createTitle(RouteTitles.INDEX.KVKK)
            }
        },
        {
            name: 'Üyelik Sözleşmesi',
            path: 'uyelik-sozlesmesi',
            component: () => import('@/views/index/UyelikSozlesmesi.vue'),
            meta: {
                title: createTitle(RouteTitles.INDEX.MEMBERSHIP_AGREEMENT)
            }
        }
    ]
};

export default IndexRoutes;
