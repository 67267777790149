import type { AppRouteType } from './types';
import { RoutePaths, RouteTitles, createTitle } from './router.constants';

const ECRRoutes: AppRouteType = {
    path: RoutePaths.ECR.ROOT,
    meta: {
        requiresAuth: true,
        title: createTitle(RouteTitles.ECR.ROOT),
        description: 'ECR',
        keywords: 'gemimakine, ecr, üye paneli, test, soru, görüntüle'
    },
    redirect: RoutePaths.ECR.DASHBOARD,
    component: () => import('@/layouts/dashboard-layout/DashboardLayout.vue'),
    children: [
        {
            name: 'Profile',
            path: 'profile',
            component: () => import('@/views/ecr/Profile.vue'),
            meta: {
                title: createTitle(RouteTitles.ECR.PROFILE),
                description: 'Profilinizi görüntüleyin ve düzenleyin',
                keywords: 'gemimakine, hesap, profil, düzenle'
            }
        },
        {
            name: 'TestMaker',
            path: 'test-maker',
            component: () => import('@/views/ecr/test/TestMaker.vue'),
            meta: {
                title: createTitle(RouteTitles.ECR.TEST_MAKER),
                description: 'Test oluşturucu',
                keywords: 'gemimakine, test, oluştur, test oluşturucu'
            }
        },
        {
            name: 'Test',
            path: 'test',
            component: () => import('@/views/ecr/test/Test.vue'),
            props: true,
            meta: {
                title: createTitle(RouteTitles.ECR.TEST),
                description: 'Test çöz',
                keywords: 'gemimakine, test, çöz, test çöz'
            }
        },
        {
            name: 'TestResult',
            path: 'test-result',
            component: () => import('@/views/ecr/test/TestResult.vue'),
            meta: {
                title: createTitle(RouteTitles.ECR.TEST_RESULT),
                description: 'Test sonucunuzu görüntüleyin',
                keywords: 'gemimakine, test, sonuç, görüntüle'
            }
        },
        {
            name: 'TestResultHistory',
            path: 'test-result-history',
            component: () => import('@/views/ecr/TestResultHistory.vue'),
            meta: {
                title: createTitle(RouteTitles.ECR.TEST_RESULT_HISTORY),
                description: 'Test sonuçlarınızı görüntüleyin',
                keywords: 'gemimakine, test, sonuç, görüntüle'
            }
        },
        {
            name: 'Dashboard',
            path: 'dashboard',
            component: () => import('@/views/ecr/Dashboard.vue'),
            meta: {
                title: createTitle(RouteTitles.ECR.DASHBOARD),
                description: 'Dashboard',
                keywords: 'gemimakine, dashboard, pano'
            }
        },
        {
            name: 'SavedQuestions',
            path: 'saved-questions',
            component: () => import('@/views/ecr/SavedQuestions.vue'),
            meta: {
                title: createTitle(RouteTitles.ECR.SAVED_QUESTIONS),
                description: 'Kaydedilen sorularınızı görüntüleyin',
                keywords: 'gemimakine, test, soru, görüntüle'
            }
        },
        {
            name: 'PerformanceAnalysis',
            path: 'performance-analysis',
            component: () => import('@/views/ecr/PerformanceAnalysis.vue'),
            meta: {
                title: createTitle(RouteTitles.ECR.PERFORMANCE_ANALYSIS),
                description: 'Performans analizi',
                keywords: 'gemimakine, test, performans, analiz'
            }
        },
        {
            name: 'Library',
            path: 'library',
            component: () => import('@/views/ecr/Library.vue'),
            meta: {
                title: createTitle(RouteTitles.ECR.LIBRARY),
                description: 'Kütüphane',
                keywords: 'gemimakine, test, kütüphane'
            }
        }
    ]
};

export default ECRRoutes;
