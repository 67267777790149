import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth.store'
import { errorLoggerService } from '@/services/errorLogger.service'

export interface ErrorLog {
    id?: string;
    message: string;
    stack?: string;
    timestamp: Date;
    userId?: string | null;
    userAgent: string;
    path: string;
    componentName?: string;
    severity: 'error' | 'warning' | 'info';
    metadata?: Record<string, any>;
}

export function useErrorLogger() {
  const router = useRouter()
  const authStore = useAuthStore()

  const logError = async (error: Error | unknown, additionalInfo?: Record<string, any>) => {
    try {
      const errorLog: ErrorLog = {
        message: error instanceof Error ? error.message : String(error),
        stack: error instanceof Error ? error.stack : undefined,
        timestamp: new Date(),
        userId: authStore.user?.id,
        userAgent: navigator.userAgent,
        path: router.currentRoute.value.path,
        componentName: router.currentRoute.value.name?.toString(),
        severity: 'error',
        metadata: {
          ...additionalInfo,
          routeParams: router.currentRoute.value.params,
          routeQuery: router.currentRoute.value.query
        }
      }

      await errorLoggerService.logError(errorLog)
      console.error('Hata loglandı:', errorLog)
    } catch (loggingError) {
      console.error('Hata loglanırken problem oluştu:', loggingError)
    }
  }

  const logWarning = async (message: string, additionalInfo?: Record<string, any>) => {
    const warningLog: ErrorLog = {
      message,
      timestamp: new Date(),
      userId: authStore.user?.id,
      userAgent: navigator.userAgent,
      path: router.currentRoute.value.path,
      componentName: router.currentRoute.value.name?.toString(),
      severity: 'warning',
      metadata: additionalInfo
    }

    try {
      await errorLoggerService.logError(warningLog)
    } catch (error) {
      console.error('Uyarı loglanırken hata oluştu:', error)
    }
  }

  return {
    logError,
    logWarning
  }
} 