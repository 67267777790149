import { defineStore } from "pinia"
import type { User } from "@/core/interfaces/user"
import { AuthService } from "@/services/auth.service"
import { ref, computed } from 'vue'
import { ApiException } from "@/core/types/api.types"
import type { ResetPasswordData, ChangePasswordData } from "@/services/auth.service"
import { AxiosError } from 'axios'
import { useAnalytics } from '@/composables/useAnalytics'
import { useUserStore } from '@/stores/user.store'

const { trackEvent, trackUserProperties } = useAnalytics()

export interface LoginData {
    email: string,
    password: string
}

export interface RegisterData {
    email: string,
    first_name: string,
    last_name: string,
    password: string,
    password_confirm: string,
    package_id: string
}

export const useAuthStore = defineStore('auth', () => {
    const user = ref<User | null>(null)
    const isAuthenticated = ref(false)

    const userDetail = computed(() => user.value)

    const checkSession = async () => {
        try {
            const response = await AuthService.checkSession()
            isAuthenticated.value = response.data.isValid
            
            if (isAuthenticated.value && !user.value) {
                await getUser()
            }
            
            return isAuthenticated.value
        } catch (error) {
            $reset()
            return false
        }
    }

    const login = async (payload: LoginData) => {
        try {
            const response = await AuthService.login(payload)
            user.value = response.data.user
            isAuthenticated.value = true
            
            trackUserProperties({
                user_id: user.value.id,
                package_name: user.value.package?.package_name,
                membership_valid: user.value.membership_valid
            })

            trackEvent({
                category: 'Authentication',
                action: 'Login',
                label: 'User Login Success'
            })

            return response
        } catch (error) {
            trackEvent({
                category: 'Authentication',
                action: 'Login',
                label: 'User Login Failed'
            })
            if (error && typeof error === 'object' && 'response' in error) {
                const axiosError = error as AxiosError<any>
                if (axiosError.response?.data) {
                    throw new ApiException(
                        axiosError.response.status,
                        axiosError.response.data.message,
                        axiosError.response.data.errors || []
                    )
                }
            }
            throw error
        }
    }

    const register = async (payload: RegisterData) => {
        return await AuthService.register(payload)
    }

    const getUser = async () => {
        const response = await AuthService.getUser()
        user.value = response.data
        return response
    }

    const $reset = () => {
        user.value = null
        isAuthenticated.value = false
    }

    const logout = async () => {
        try {
            await AuthService.logout()
        } finally {
            $reset()
            const userStore = useUserStore()
            userStore.$reset()
            
            trackEvent({
                category: 'Authentication',
                action: 'Logout',
                label: 'User Logout Success'
            })
            isAuthenticated.value = false
            user.value = null
        }
    }

    const confirmEmail = async (token: string) => {
        try {
            return await AuthService.confirmEmail(token)
        } catch (error) {
            if (error && typeof error === 'object' && 'response' in error) {
                const axiosError = error as AxiosError<any>
                if (axiosError.response?.data) {
                    throw new ApiException(
                        axiosError.response.status,
                        axiosError.response.data.message,
                        axiosError.response.data.errors || []
                    )
                }
            }
            throw error
        }
    }

    const resendConfirmationLink = async (email: string) => {
        return await AuthService.resendConfirmationLink(email)
    }

    const resetPasswordRequest = async (email: string) => {
        return await AuthService.resetPasswordRequest(email)
    }

    const resetPassword = async (payload: ResetPasswordData) => {
        return await AuthService.resetPassword(payload)
    }

    const changePassword = async (data: ChangePasswordData) => {
        try {
            const response = await AuthService.changePassword(data)
            return response.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw error.response?.data
            }
            throw error
        }
    }

    const refreshToken = async () => {
        try {
            await AuthService.refresh()
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiException(
                    error.response?.status || 500,
                    error.response?.data?.message || 'Token yenileme başarısız',
                    error.response?.data?.errors || []
                );
            }
            throw error;
        }
    }

    return {
        user,
        isAuthenticated,
        userDetail,
        checkSession,
        login,
        register,
        getUser,
        logout,
        confirmEmail,
        resendConfirmationLink,
        resetPasswordRequest,
        resetPassword,
        changePassword,
        refreshToken
    }
}, {
    persist: {
        key: 'auth',
        storage: sessionStorage,
        paths: ['user', 'isAuthenticated']
    }
})