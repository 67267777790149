import type { ErrorLog } from '@/composables/useErrorLogger'
import { useApi } from '@/composables/useApi'

class ErrorLoggerService {
    private readonly api = useApi()
    private readonly BASE_URL = '/error-logs'

    async logError(errorLog: ErrorLog): Promise<void> {
        try {
            await this.api.post(this.BASE_URL, errorLog)
        } catch (error) {
            // Hata loglama servisi çalışmazsa, console'a yaz
            console.error('Hata log servisi çalışmıyor:', error)
            console.error('Loglanmayan hata:', errorLog)
        }
    }
}

export const errorLoggerService = new ErrorLoggerService() 