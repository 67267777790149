import { useApi, useApiPrivate } from "@/composables/useApi"
import { API_URL, AUTH_ENDPOINTS } from "@/core/constants/api.constants"
import type { LoginData, RegisterData } from "@/stores/auth.store"
import type { ApiResponse } from "@/core/types/api.types"
import type { User } from "@/core/interfaces/user"

interface LoginResponse {
    user: User;
}

export interface ResetPasswordData {
    token: string;
    password: string;
    password_confirm: string;
}

export interface ChangePasswordData {
    currentPassword: string;
    newPassword: string;
}

export const AuthService = {
    async login(payload: LoginData): Promise<ApiResponse<LoginResponse>> {
        return (await useApi().post(API_URL + AUTH_ENDPOINTS.LOGIN, payload)).data;
    },

    async register(payload: RegisterData): Promise<ApiResponse<void>> {
        return (await useApi().post(API_URL + AUTH_ENDPOINTS.REGISTER, payload)).data;
    },

    async getUser(): Promise<ApiResponse<User>> {
        return (await useApiPrivate().get(API_URL + AUTH_ENDPOINTS.USER)).data;
    },

    async logout(): Promise<ApiResponse<void>> {
        return (await useApiPrivate().get(API_URL + AUTH_ENDPOINTS.LOGOUT)).data;
    },

    async checkSession(): Promise<ApiResponse<{isValid: boolean}>> {
        return (await useApi().get(API_URL + AUTH_ENDPOINTS.CHECK_SESSION)).data;
    },

    async confirmEmail(token: string): Promise<ApiResponse<void>> {
        return (await useApi().get(API_URL + AUTH_ENDPOINTS.CONFIRM + `/${token}`)).data;
    },

    async resendConfirmationLink(email: string): Promise<ApiResponse<void>> {
        return (await useApi().post(API_URL + AUTH_ENDPOINTS.RESEND_CONFIRMATION, { email })).data;
    },

    async resetPasswordRequest(email: string): Promise<ApiResponse<void>> {
        return (await useApi().post(API_URL + AUTH_ENDPOINTS.RESET_PASSWORD_REQUEST, { email })).data;
    },

    async resetPassword(payload: ResetPasswordData): Promise<ApiResponse<void>> {
        return (await useApi().post(API_URL + AUTH_ENDPOINTS.RESET_PASSWORD, payload)).data;
    },

    async changePassword(data: ChangePasswordData): Promise<ApiResponse<null>> {
        return (await useApiPrivate().post(API_URL + AUTH_ENDPOINTS.CHANGE_PASSWORD, data)).data;
    },

    async refresh(): Promise<ApiResponse<void>> {
        return (await useApiPrivate().get(API_URL + AUTH_ENDPOINTS.REFRESH)).data;
    }
} 