import { useApiPrivate } from "@/composables/useApi"
import { API_URL, USER_ENDPOINTS } from "@/core/constants/api.constants"
import type { ApiResponse } from "@/core/types/api.types"
import type { Question } from "@/core/interfaces/question"
import type { TestResult } from "@/core/interfaces/test_result"

interface SavedQuestionsResponse {
    questions: Question[];
}

interface TestHistoryResponse {
    test_history: TestResult[];
}

export const UserService = {
    async getSavedQuestions(): Promise<ApiResponse<SavedQuestionsResponse>> {
        try {
            return (await useApiPrivate().get(API_URL + USER_ENDPOINTS.SAVED_QUESTIONS)).data;
        } catch (error) {
            throw error;
        }
    },

    async unsaveQuestions(payload: { question_ids: string[] }): Promise<ApiResponse<void>> {
        try {
            return await useApiPrivate().post(API_URL + USER_ENDPOINTS.UNSAVE_QUESTIONS, payload);
        } catch (error) {
            throw error;
        }
    },

    async getTestHistory(last_date?: string): Promise<ApiResponse<TestHistoryResponse>> {
        try {
            return (await useApiPrivate().post(API_URL + USER_ENDPOINTS.TEST_HISTORY, { last_date })).data;
        } catch (error) {
            throw error;
        }
    },

}